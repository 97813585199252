<template>
  <div id="extra-component-chartist-demo">
    <vx-card title="รายการของเว็ป">
      <div class="vx-row">
        <div class="vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3">
          <label class="vs-input--label">เลือกเว็บในเครือ</label>
          <vs-select class="w-full mt-4" v-model="web">
            <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in webOptions" />
          </vs-select>
          <br>
        </div>
        <div class="vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3">
          <br>
        </div>
      </div>
      <vs-button color="primary" @click="selectWeb(web)">ค้นหา</vs-button>
    </vx-card>
    <!-- ROW 1-->
    <div class="vx-row" v-if="check_permission == true">
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
        <statistics-card-line hideChart class="mb-base" icon="PlusCircleIcon" :statistic="data.topup_today"
          statisticTitle="ยอดฝาก" color="success" />
      </div>

      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
        <statistics-card-line hideChart class="mb-base" icon="MinusCircleIcon" statisticTitle="ยอดถอน"
          :statistic="data.withdraw_today" />
      </div>

      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
        <statistics-card-line hideChart class="mb-base" icon="DollarSignIcon" :statistic="data.profit"
          statisticTitle="กำไร" color="warning" />
      </div>

      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
        <statistics-card-line hideChart class="mb-base" icon-pack="feather" icon="UserCheckIcon"
          :statistic="data.count_uservip_today" statisticTitle="เปิดยูสเติมเงิน" color="danger" />
      </div>
    </div>

    <!-- ROW 2 -->
    <div class="vx-row">

      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
        <statistics-card-line hideChart class="mb-base" icon="FilePlusIcon" icon-right
          :statistic="data.count_topup_today" statisticTitle="รายการฝาก" color="success" />
      </div>

      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
        <statistics-card-line hideChart class="mb-base" icon="FileMinusIcon" icon-right
          :statistic="data.count_withdraw_today" :statisticTitle="'รายการถอน ('+ data.avg_withdrawtime + ')'" />
      </div>

      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
        <statistics-card-line hideChart class="mb-base" icon="CheckSquareIcon" icon-right
          :statistic="data.count_bonus_today" statisticTitle="รายการฝากที่รับโบนัส" color="warning" />
      </div>

      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
        <statistics-card-line hideChart class="mb-base" icon="UserMinusIcon" icon-right :statistic="data.aff_today"
          statisticTitle="ยอดโยกเงินแนะนำเพื่อน" color="primary" />
      </div>
    </div>

    <div class="vx-row" v-if="check_permission == true">
      <!-- LINE AREA CHART -->
      <div class="vx-col md:w-2/2 w-full mb-base">
        <vx-card title="สรุป 7 วันย้อนหลัง">
          <vue-apex-charts type="area" height="350" :options="lineAreaChartSpline.chartOptions"
            :series="lineAreaChartSpline.series"></vue-apex-charts>
        </vx-card>
      </div>

      <!-- COLUMN CHART -->
      <div class="vx-col md:w-2/2 w-full mb-base">
        <vx-card title="ยอดฝากย้อนหลัง 7 วัน">
          <vue-apex-charts type="bar" height="350" :options="columnChart.chartOptions" :series="columnChart.series">
          </vue-apex-charts>
        </vx-card>
      </div>

    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import axios from '../../axios'
const themeColors = ['#28C76F', '#EA5455', '#EA5455', '#FF9F43', '#1E1E1E']
export default {
  data () {
    return {
      //themeColors: ['#7367F0', '#28C76F', '#EA5455', '#FF9F43', '#1E1E1E'],
      lineAreaChartSpline: {
        series: [],
        chartOptions: {
          noData: {
            text: 'Loading...'
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'smooth'
          },
          colors: themeColors,
          xaxis: {
            type: 'date',
            categories: [
              this.moment().subtract(6, 'days').format('YYYY-MM-DD'), this.moment().subtract(5, 'days').format(
                'YYYY-MM-DD'), this.moment().subtract(4, 'days').format('YYYY-MM-DD'),
              this.moment().subtract(3, 'days').format('YYYY-MM-DD'), this.moment().subtract(2, 'days').format(
                'YYYY-MM-DD'), this.moment().subtract(1, 'days').format('YYYY-MM-DD'),
              this.moment().format('YYYY-MM-DD')
            ]
          },
          yaxis: {
            title: {
              text: '฿ (บาท)'
            }
          },
          tooltip: {
            x: {
              format: 'dd/MM/yy HH:mm'
            },
            y: {
              formatter (val) {
                return `${String(val.toFixed(2)).replace(/\d(?=(\d{3})+\.)/g, '$&,')} บาท`
              }
            }

          }
        }
      },
      columnChart: {
        series: [],
        chartOptions: {
          noData: {
            text: 'Loading...'
          },
          colors: this.themeColors,
          plotOptions: {
            bar: {
              horizontal: false,
              endingShape: 'rounded',
              columnWidth: '50%'
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },

          xaxis: {
            categories: [
              this.moment().subtract(6, 'days').format('YYYY-MM-DD'), this.moment().subtract(5, 'days').format(
                'YYYY-MM-DD'), this.moment().subtract(4, 'days').format('YYYY-MM-DD'),
              this.moment().subtract(3, 'days').format('YYYY-MM-DD'), this.moment().subtract(2, 'days').format(
                'YYYY-MM-DD'), this.moment().subtract(1, 'days').format('YYYY-MM-DD'),
              this.moment().format('YYYY-MM-DD')
            ]
          },
          yaxis: {
            title: {
              text: '฿ (บาท)'
            }
          },
          fill: {
            opacity: 1

          },
          tooltip: {
            y: {
              formatter (val) {
                return `${String(val.toFixed(2)).replace(/\d(?=(\d{3})+\.)/g, '$&,')} บาท`
              }
            }
          }
        }
      },
      data7day: [],
      topup: [],
      withdraw: [],
      deposit: [],
      deposit_wallet: [],
      deposit_scb: [],
      deposit_kbank: [],
      deposit_admin: [],
      getdata: [],
      data: {
        topup_today: 0,
        withdraw_today: 0,
        profit: 0,
        count_uservip_today: 0,
        count_topup_today: 0,
        count_withdraw_today: 0,
        count_bonus_today: 0,
        aff_today: 0,
        avg_withdrawtime: 0
      },
      datapermission: [],
      level_permission: this.$store.state.AppActiveUser.lv,
      check_permission: false,
      webOptions: [
        {
          text: 'Sagame1688',
          value: '0',
          subStatus:false
        },
        {
          text: 'London1688',
          value: '1',
          subStatus:false
        },
        {
          text: 'SlotVvip88',
          value: '2',
          subStatus:false
        },
        {
          text: 'Ufabomb',
          value: '3',
          subStatus:false
        },
        {
          text: 'lalika168',
          value: '4',
          sub: '0',
          subStatus:true
        },
        {
          text: 'All',
          value: '5',
          subStatus:false
        }
      ],
      web: '0',
      nameArr : [process.env.VUE_APP_API_URL_SA, process.env.VUE_APP_API_URL_LONDON, process.env.VUE_APP_API_URL_VVIP, process.env.VUE_APP_API_URL_BOMB, process.env.VUE_APP_API_URL_LALIKABET],
      subUrlArr : [process.env.VUE_APP_NAME_LALIKABET_168]
    }
  },
  components: {
    VueApexCharts,
    StatisticsCardLine
  },
  async mounted () {


    //GET DATA FOR columnChart Charts
    this.chartColumn(this.web)
    //GET DATA FOR TOP BOX
    this.boxData(this.web)
    //GET DATA FOR lineAreaChartSpline Charts
    this.chartLine(this.web)


    const {
      permissions
    } = this.$store.state.AppActiveUser

    if (permissions && permissions.dashboard.read) {
      this.check_permission = true
    }
  },
  methods: {
    currency (amount) {
      //SET NULL OR NaN = 0
      if (!amount || amount === 'NaN') {
        amount = 0
      }
      if (amount >= 0) {
        if (typeof amount === 'number') {
          amount = String(Math.abs(amount))
        }
        return (amount).replace(/\d(?=(\d{3})+\.)/g, '$&,')
      } else {
        return `${(amount).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
      }

    },
    selectWeb (value) {
      this.boxData(value)
      this.chartLine(value)
      this.chartColumn(value)
    },
    async boxData (valueNumberWeb) {
      const d = {
        todayTopup : 0,
        todayWithdraw : 0,
        todayCountUserVip : 0,
        todayCountTopup : 0,
        todayCountWithdraw : 0,
        todayCountBonus : 0,
        todayCountAff : 0,
        avgWithdrawTime : 0
      }
      if (this.nameArr[valueNumberWeb]) {
        await axios
          .get(await this.genUrl(valueNumberWeb, '/dashboardchart/data'))
          .then(response => (this.getdata = response.data))

        d.todayTopup = this.getdata.topup_today
        d.todayWithdraw = this.getdata.withdraw_today
        d.todayCountUserVip = this.getdata.count_uservip_today
        d.todayCountTopup = this.getdata.count_topup_today
        d.todayCountWithdraw = this.getdata.count_withdraw_today
        d.todayCountBonus = this.getdata.count_bonus_today
        d.todayCountAff =  this.getdata.aff_today
        d.avgWithdrawTime =  this.getdata.avg_withdrawtime


      } else {

        for (let i = 0; i < valueNumberWeb; i++) {

          await axios
            .get(await this.genUrl(i, '/dashboardchart/data'))
            .then(response => (this.getdata = response.data))

          d.todayTopup =  parseFloat(d.todayTopup) + parseFloat(this.getdata.topup_today)
          d.todayWithdraw = parseFloat(d.todayWithdraw) + parseFloat(this.getdata.withdraw_today)
          d.todayCountUserVip = parseFloat(d.todayCountUserVip) + parseFloat(this.getdata.count_uservip_today)
          d.todayCountTopup = parseFloat(d.todayCountTopup) + parseFloat(this.getdata.count_topup_today)
          d.todayCountWithdraw = parseFloat(d.todayCountWithdraw) + parseFloat(this.getdata.count_withdraw_today)
          d.todayCountBonus = parseFloat(d.todayCountBonus) +  parseFloat(this.getdata.count_bonus_today)
          d.todayCountAff = parseFloat(d.todayCountAff) +  parseFloat(this.getdata.aff_today)
          d.avgWithdrawTime = parseFloat(d.avgWithdrawTime) + parseFloat(this.getdata.avg_withdrawtime)

        }
      }
      this.data.topup_today = this.currency(parseFloat(d.todayTopup).toFixed(2))
      this.data.withdraw_today = this.currency(parseFloat(d.todayWithdraw).toFixed(2))
      this.data.profit = d.todayTopup  -  d.todayWithdraw
      this.data.profit = this.data.profit.toFixed(2)
      this.data.profit = this.currency(this.data.profit)
      this.data.count_uservip_today = this.currency(d.todayCountUserVip)
      this.data.count_topup_today = this.currency(d.todayCountTopup)
      this.data.count_withdraw_today = this.currency(d.todayCountWithdraw)
      this.data.count_bonus_today = this.currency(d.todayCountBonus)
      this.data.aff_today = this.currency(d.todayCountAff)
      this.data.avg_withdrawtime = d.avgWithdrawTime
    },
    async chartLine (valueNumberWeb) {

      if (this.nameArr[valueNumberWeb]) {
        await axios
          .get(await this.genUrl(valueNumberWeb, '/dashboardchart/datachart'))
          .then(response => (this.data7day = response.data))
        this.topup = []
        this.withdraw = []
        for (let i = 0; i < 7; i++) {
          this.topup.push(this.data7day.topup[i].sum)
          this.withdraw.push(this.data7day.withdraw[i].sum)
        }
        this.topup.reverse()
        this.withdraw.reverse()
        this.lineAreaChartSpline.series = [
          {
            name: 'topup',
            data: this.topup
          },
          {
            name: 'withdraw',
            data: this.withdraw
          }
        ]
      } else {
        const allLoopData = []
        const topup = [0, 0, 0, 0, 0, 0, 0]
        const withdraw = [0, 0, 0, 0, 0, 0, 0]
        for (let i = 0; i < valueNumberWeb; i++) {

          await axios
            .get(await this.genUrl(i, '/dashboardchart/datachart'))
            .then(response => (this.data7day = response.data))

          allLoopData.push(this.data7day)
        }

        allLoopData.forEach(element => {

          for (let i = 0; i < 7; i++) {

            if (topup[i].sum === 0) {
              topup[i] = element.topup[i].sum


            } else if (topup[i].sum === 0) {
              withdraw[i] = element.withdraw[i].sum
            } else {
              topup[i] = parseFloat(topup[i]) + parseFloat(element.topup[i].sum)
              withdraw[i] = parseFloat(withdraw[i]) + parseFloat(element.withdraw[i].sum)
            }
          }

        })
        this.topup = topup
        this.withdraw = withdraw
        this.topup.reverse()
        this.withdraw.reverse()
        this.lineAreaChartSpline.series = [
          {
            name: 'topup',
            data: this.topup
          },
          {
            name: 'withdraw',
            data: this.withdraw
          }
        ]


      }
    },
    async chartColumn (valueNumberWeb) {

      if (this.nameArr[valueNumberWeb]) {

        await axios

          .get(await this.genUrl(valueNumberWeb, '/dashboardchart/depositchart'))
          .then(response => (this.deposit = response.data))
        this.deposit_wallet = []
        this.deposit_scb = []
        this.deposit_kbank = []
        this.deposit_admin = []
        for (let i = 0; i < 7; i++) {
          this.deposit_wallet.push(this.deposit.wallet[i].sum)
          this.deposit_scb.push(this.deposit.scb[i].sum)
          this.deposit_kbank.push(this.deposit.kbank[i].sum)
          this.deposit_admin.push(this.deposit.admin[i].sum)
        }
        this.deposit_wallet.reverse()
        this.deposit_scb.reverse()
        this.deposit_kbank.reverse()
        this.deposit_admin.reverse()
        this.columnChart.series = [
          {
            name: 'wallet',
            data: this.deposit_wallet
          },
          {
            name: 'scb',
            data: this.deposit_scb
          },
          {
            name: 'kbank',
            data: this.deposit_kbank
          },
          {
            name: 'admin',
            data: this.deposit_admin
          }
        ]

      } else {
        const allLoopData = []
        const admin = [0, 0, 0, 0, 0, 0, 0]
        const kbank = [0, 0, 0, 0, 0, 0, 0]
        const scb = [0, 0, 0, 0, 0, 0, 0]
        const wallet = [0, 0, 0, 0, 0, 0, 0]
        for (let i = 0; i < valueNumberWeb; i++) {

          await axios
            .get(await this.genUrl(i, '/dashboardchart/depositchart'))
            .then(response => (this.deposit = response.data))
          allLoopData.push(this.deposit)

        }

        allLoopData.forEach(element => {


          for (let i = 0; i < 7; i++) {

            if (admin[i].sum === 0) {
              admin[i] = element.admin[i].sum

            } else if (kbank[i].sum === 0) {
              kbank[i] = element.kbank[i].sum

            } else if (scb[i].sum === 0) {
              scb[i] = element.scb[i].sum

            } else if (wallet[i].sum === 0) {
              wallet[i] = element.wallet[i].sum

            } else {
              admin[i] = parseFloat(admin[i]) + parseFloat(element.admin[i].sum)
              kbank[i] = parseFloat(kbank[i]) + parseFloat(element.kbank[i].sum)
              scb[i] = parseFloat(scb[i]) + parseFloat(element.scb[i].sum)
              wallet[i] = parseFloat(wallet[i]) + parseFloat(element.wallet[i].sum)
            }
          }

        })

        this.deposit_wallet = wallet
        this.deposit_scb = scb
        this.deposit_kbank = kbank
        this.deposit_admin = admin

        this.deposit_wallet.reverse()
        this.deposit_scb.reverse()
        this.deposit_kbank.reverse()
        this.deposit_admin.reverse()
        this.columnChart.series = [
          {
            name: 'wallet',
            data: this.deposit_wallet
          },
          {
            name: 'scb',
            data: this.deposit_scb
          },
          {
            name: 'kbank',
            data: this.deposit_kbank
          },
          {
            name: 'admin',
            data: this.deposit_admin
          }
        ]
      }
    },
    genUrl (valueNumberWeb, fixPath) {
      if (this.webOptions[valueNumberWeb].subStatus === true) {
        return `${this.nameArr[valueNumberWeb]}${fixPath}/${this.subUrlArr[this.webOptions[valueNumberWeb].sub]}`
      } else {
        return `${this.nameArr[valueNumberWeb]}${fixPath}`
      }
    }
  }
}

</script>
